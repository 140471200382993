
  import { PushNotifications } from '@capacitor/push-notifications';
  import { Capacitor } from '@capacitor/core';
  
  window.notifications = function() {
    if (Capacitor.getPlatform() === 'ios' || Capacitor.getPlatform() === 'android') {
      // Handle native apps (iOS/Android)
      PushNotifications.requestPermissions().then(result => {
        if (result.receive === 'granted') {
          console.log('Requesting push notification registration...');
          PushNotifications.register().then(() => {
            console.log('Registration process initiated');
          }).catch(error => {
            console.error('Error during registration process:', error);
          });
        } else {
          console.error('Push notification permission not granted');
        }
      });
  
      // Handle push registration
      PushNotifications.addListener('registration', async (token) => {
        console.log('Push registration success, token: ' + token.value);
  
        try {
          const response = await fetch('https://amezianbackend-378a44d933c2.herokuapp.com/api/storePushNotificationID', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.token}`
            },
            body: JSON.stringify({ pushNotificationID: token.value })
          });
  
          if (!response.ok) {
            throw new Error('Failed to store push notification ID on the server.');
          }
  
          console.log('Push notification ID successfully stored on the server.');
        } catch (error) {
          console.error('Error storing push notification ID:', error);
        }
      });
  
      // Handle registration errors
      PushNotifications.addListener('registrationError', (error) => {
        console.error('Error on registration: ' + JSON.stringify(error));
      });
  
      // Handle incoming notifications
      PushNotifications.addListener('pushNotificationReceived', (notification) => {
        console.log('Push notification received: ', notification);
      });
  
      // Handle notification tap actions
      PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
        console.log('Push action performed: ', notification);
      });
    } else {
      if (localStorage.user) {
                    try {
                        var user = JSON.parse(localStorage.user);
                        
                    } catch(e) {
                        console.error("Error parsing user data from localStorage:", e);
                    }
                  }
      
        
      // Handle web push notifications for Progressive Web App
      if ('serviceWorker' in navigator && 'PushManager' in window) {
        console.log('Service Worker and Push Manager supported');
  
        navigator.serviceWorker.register('./sw.js').then(function(swReg) {
          console.log('Service Worker registered', swReg);
  
          // Request permission for push notifications
          Notification.requestPermission().then(function(permission) {
            if (permission === 'granted') {
              console.log('Notification permission granted.');
  
              // Subscribe to push notifications
              swReg.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: 'BGnacPtlOnAcuwVgqaPWM0fO_l2uW37noB_b3R87KTKF0idB_GhesqDBWtRmZ8tuSPzj9sKoToRqAX4oPKdxJ7U' // Replace with your VAPID public key
              }).then(async function(subscription) {
                console.log('Push subscription:', subscription);
                const subscriptionString = JSON.stringify(subscription);
                console.log(subscriptionString);
                // Send the subscription to your server
                try {
                  const response = await fetch('https://amezianbackend-378a44d933c2.herokuapp.com/api/storePushNotificationID', {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                      'Authorization': `Bearer ${localStorage.token}`
                    },
                    body: JSON.stringify({ pushNotificationID: subscriptionString })
                  });
  
                  if (!response.ok) {
                    throw new Error('Failed to store push notification subscription on the server.');
                  }
  
                  console.log('Push notification subscription successfully stored on the server.');
                } catch (error) {
                  console.error('Error storing push notification subscription:', error);
                }
              }).catch(function(error) {
                console.error('Error during push subscription:', error);
              });
            } else {
              console.error('Notification permission not granted.');
            }
          });
        }).catch(function(error) {
          console.error('Service Worker registration error:', error);
        });
      } else {
        console.error('Push messaging is not supported in this browser.');
      }
    }
  }
  