
      import { Camera, CameraResultType } from '@capacitor/camera';

      document.getElementById('openCamera').addEventListener('click', async () => {
        try {
          const image = await Camera.getPhoto({
            quality: 90,
            allowEditing: false,
            presentationStyle: 'popover',
            resultType: CameraResultType.Uri,
            promptLabelHeader: 'Photo',        // Label for the prompt header
            promptLabelCancel: 'Annuler',      // Label for the 'cancel' button
            promptLabelPhoto: 'Depuis Photos', // Label for the button to select a saved image
            promptLabelPicture: 'Prendre une photo' // Label for the button to take a new picture
          });


          console.log(image.webPath); // Outputs the path or URL to the image
          // You can now display the image or use it in your app
           // Convert the captured image URI to a File object
          const response = await fetch(image.webPath); // Fetch the file data from the URI
          const blob = await response.blob(); // Convert response to a Blob
          const fileName = `photo_${Date.now()}.${blob.type.split('/')[1]}`; // Generate a filename based on the timestamp and type
          const file = new File([blob], fileName, { type: blob.type }); // Create a File object

          // Create a hidden input element and attach the file to it
          const input = document.createElement('input');
          input.type = 'file';
          input.style.display = 'none'; // Ensure it's not visible
          document.body.appendChild(input);

          // Create a DataTransfer object and add the file to it
          const dataTransfer = new DataTransfer();
          dataTransfer.items.add(file);
          input.files = dataTransfer.files;

          // Now pass this input element to your existing sendPrivateFile function
          window.sendPrivateFile(input);

          // Cleanup: Remove the hidden input element after use
          document.body.removeChild(input);
        } catch (error) {
          console.error('Camera error:', error);
        }
    });

    